<template>
  <div>
    <!-- 會員身分異常 -->
    <Dialog
      class="notice-dialog"
      :dialog="openAbnormalDialog"
      title="會員身分異常"
    >
      <p class="notice_title">提醒：</p>
      <p class="notice_content">
        此活動為會員專屬活動，目前查無您的會員資料。請關閉視窗至會員中心確認會員身分、或註冊會員。
      </p>
      <BaseBtn
        text="關閉視窗"
        :isRounded="true"
        @click="closeAbnormalDialog"
      />
    </Dialog>
  </div>
</template>

<script>
import liff from "@line/liff";
import Dialog from "@/components/Dialog.vue";
import BaseBtn from "@/components/BaseBtn.vue";

export default {
  components: {
    Dialog,
    BaseBtn,
  },
  data() {
    return {
      openAbnormalDialog: false,
    };
  },
  created() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
        this.lineInit();
    } else {
      alert(
        "此活動不支援桌上型裝置，請使用行動裝置加入ASAHI LINE官方帳號參與活動"
      );
      window.location.href = "https://lin.ee/QcLJ44n";
    }
  },
  methods: {
    async lineInit() {
      //liff Init
      await liff
        .init({
          liffId: process.env.VUE_APP_LIFFID, // project liff id
        })
        .then(() => {
          //detect login or not
          if (!liff.isLoggedIn()) {
            liff.login({ redirectUri: window.location.href });
          } else {
            liff.getFriendship().then((data) => {
              if (data.friendFlag) {
                this.liffGetProfile();
              } else {
                this.linkToAddFriend();
              }
            });
          }
        })
        .catch((err) => {
          console.log(err.code, err.message);
        });
    },
    liffGetProfile() {
      liff
        .getProfile()
        .then((res) => {
          window.localStorage.setItem("userToken", res.userId);
          window.localStorage.setItem("userName", res.displayName);
          this.checkUser();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    linkToAddFriend() {
      window.location.replace(process.env.VUE_APP_ADDFRIEND); //加好友連結
    },
    async checkUser() {
      this.$store.commit("updateLoading", true);
      let url =
        process.env.VUE_APP_APIUrl + "api/v1/users/check-user-registered";
      let data = {
        lineToken: window.localStorage.getItem("userToken"),
      };
      await this.axios
        .post(url, data)
        .then((res) => {
          let state = res.data.data;
          console.log(state);
          this.$store.commit("updateLoading", false);
          if(state.exist && state.sync) {
            this.$router.push('/home');
          }else {
            this.openAbnormalDialog = true;
          }
        })
        .catch((e) => {
          this.$store.commit("updateLoading", false);
          console.log(e);
          this.openAbnormalDialog = true;
        });
    },
    closeAbnormalDialog() {
      this.openAbnormalDialog = false;
      liff.closeWindow();
    },
  },
};
</script>
<style lang="scss" scoped>
.v-dialog {
  .notice_title {
    margin-bottom: 6px !important;
    font-weight: 500;
  }
  .notice_content {
    margin: 10px 0 36px;
  }
}
</style>
